
import { mapGetters } from 'vuex';
import CompanionView from '../components/companion-view';

export default {
  name: 'CompanionListItem',
  components: {
    CompanionView,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    isInteractive: {
      type: Boolean,
      default: true,
    },
    forceActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('companions', [
      'companionActive',
    ]),
    companion() {
      return this.$store.getters['companions/getCompanionByName'](this.name);
    },
    isCompanionActive() {
      return this.companion?.isActive;
    },
  },
  methods: {
    handleView() {
      if (this.isInteractive) {
        this.$store.dispatch('ui/showModal', {
          name: 'companionStatus',
          payload: {
            name: this.name,
          },
        });

        this.$emit('close');
      }
    },
  },
};
