
import { mapGetters } from 'vuex';

import CompanionListItem from './companion-list-item';
import CompanionView from './companion-view';

export default {
  name: 'CompanionsList',
  components: {
    CompanionListItem,
    CompanionView,
  },
  computed: {
    ...mapGetters('companions', [
      'companions',
      'companionActive',
    ]),
  },
  methods: {
    handleView(c) {
      if (c.isKnown) {
        this.$store.dispatch('ui/showModal', {
          name: 'companionStatus',
          payload: {
            name: c.name,
          },
        });
      }
    },
  },
};
